import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ValidationPage.css'; // Import the CSS file
import logo from './logo.png'; 

function ValidationPage() {
  const [key, setkey] = useState('');
  const [ validationResult, setValidationResult] = useState('');
  const validateCertificate = async () => {
    try {
      console.log(process.env)
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/validate-certificate/${key}`);
      console.log('Client Response:', response);
  
      if (response.data.valid) {
        setValidationResult(`Certificate was issued to <strong>${response.data.name}</strong> for attending "<strong>${response.data.session_name}</strong>"`);
      } else {
        setValidationResult('Certificate not found.');
      }
    } catch (error) {
      if (error.response) {
        console.log('Error response:', error.response.data);
      } else if (error.request) {
        console.log('No response received:', error.request);
      } else {
        console.log('Error:', error.message);
      }
      setValidationResult('Certificate is not valid');
    }
  };

  return (
    <div className="container">
      <div className="header">
        <img src={logo}alt="Logo" />
        <h1>Certificate Validation System</h1>
      </div>
      <div className="field">
        <label className="label">Enter Key to Validate:</label>
        <input
          type="text"
          value={key}
          onChange={(e) => setkey(e.target.value)}
          className="input"
        />
      </div>
      <button onClick={validateCertificate} className="button">Validate Certificate</button>
      <div className="result-section">
      <p className="result" dangerouslySetInnerHTML={{ __html: validationResult }}></p>
      </div>
      <div className="footer">
        This system is developed by Udit Kumar Mahato to verify the certificates issued by Sunway College Kathmandu.
      </div>
    </div>
  );
}

export default ValidationPage;
