import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes
import MainPage from './MainPage'; // Import MainPage from the correct path
import ValidationPage from './ValidationPage';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes> {/* Use Routes instead of Switch */}
          <Route path="/sunway" element={<MainPage />} /> {/* Use element prop */}
          <Route path="/" element={<ValidationPage />} /> {/* Use element prop */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
