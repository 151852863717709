// client/src/MainPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './MainPage.css';
import logo from './logo.png'; 


function MainPage() {
  const [name, setName] = useState('');
  const [sessionName, setSessionName] = useState('');
  const [uniqueKey, setUniqueKey] = useState('');

  const generateCertificate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/generate-certificate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, session_name: sessionName }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setUniqueKey(data.unique_key);
      } else {
        const errorData = await response.json(); // Try to parse the error response
        console.error('Certificate generation failed. Server response:', errorData.error);
      }
    } catch (error) {
      console.error('Error generating certificate:', error);
    }
  };
  
  return (
    <div className="container">
      <div className="header">
      <img src={logo}alt="Logo" />
        <h1>Sunway Certificate Generation System</h1>
      </div>
      <div className="field">
        <label className="label">Full Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input"
        />
      </div>
      <div className="field">
        <label className="label">Session Name:</label>
        <input
          type="text"
          value={sessionName}
          onChange={(e) => setSessionName(e.target.value)}
          className="input"
        />
      </div>
      <button onClick={generateCertificate} className="button">Generate Certificate</button>
      <p style={{ fontSize: '20px' }}>Generated Key : <strong>{uniqueKey}</strong></p>
      <Link to="/" className="link">Go to Validation Page</Link>
      <div className="footer">
        Developed by Udit Kumar Mahato
      </div>
    </div>
  );
}

export default MainPage;
